import { EyeSlashIcon } from "@heroicons/react/24/outline";
import { forwardRef, useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

type Props = React.PropsWithChildren &
  React.HTMLAttributes<HTMLDivElement> & {
    src: string | undefined;
    overlay?: number;
  };

function getOverlayBg(level?: number) {
  if (!level) return "";
  const color = level ? `rgba(0,0,0,${level / 100})` : "";
  return `linear-gradient(0deg,${color},${color})`;
}

const BgImage: React.ForwardRefRenderFunction<HTMLDivElement, Props> = (
  { src, style, className, overlay, ...props },
  ref,
) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const reset = () => {
    setError(false);
    setLoaded(false);
  };
  useEffect(() => {
    if (!src) {
      setLoaded(true);
      setError(true);
      return reset;
    }
    if (src.endsWith(".svg")) {
      setLoaded(true);
      return reset;
    }
    const img = new Image(0, 0);
    img.src = src;
    img.onerror = () => {
      setError(true);
      setLoaded(true);
    };
    img.onload = () => setLoaded(true);
    return reset;
  }, [src]);

  const backgroundImage = [getOverlayBg(overlay), `url(${src})`].filter(
    Boolean,
  );

  return (
    <div
      ref={ref}
      {...props}
      className={twMerge(
        "relative w-full",
        "bg-gray-100 bg-cover bg-center bg-no-repeat",
        "transition ease-in-out",
        "grid place-items-center",
        !loaded && "opacity-0",
        className,
      )}
      style={Object.assign({ backgroundImage }, style)}
    >
      {error && (
        <EyeSlashIcon className="h-10 w-10 place-self-center opacity-30" />
      )}
      {props.children}
    </div>
  );
};

export default forwardRef(BgImage);
